import {CONFIG} from './config.js';

import './modules/windowInfo.js';
import './modules/resizeEnd.js';
import './modules/pcsp.js';
import './modules/viewportFit.js'
import './modules/smoothScroll.js'

import './modules/toTop.js'
import './modules/gHeader.js'
import './modules/overlay.js'

import {tree} from '/assets/js/modules/Tree.js'
tree()